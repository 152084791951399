import { EventEmitter } from '@helpers/EventEmitter';
import { resizer } from '@helpers/resizer';
import Navbar from '@components/ui/navbar/Navbar';
import bodymovin from 'bodymovin';
import Slider from '@components/UI/slider/Slider';
import AOS from 'aos';
import axios from 'axios';
import Validation from '@helpers/validation/Validation';
import Modal from './components/ui/modal/Modal';

// Your Calendly link to display in the embed
// const mainURL = 'https://calendly.com/vlshugai1/';

// Function to parse page URL for UTM parameter
// function getURLParameter(sParam) {
//     const sPageURL = window.location.search.substring(1);
//     const sURLVariables = sPageURL.split('&');
//     for (let i = 0; i < sURLVariables.length; i++) {
//         const sParameterName = sURLVariables[i].split('=');
//         if (sParameterName[0] == sParam) {
//             return sParameterName[1];
//         }
//     }
// }

// Function that will call the popup widget
// const showCalendly = function () {
//     Calendly.initPopupWidget({
//         url: mainURL,
//         prefill: {
//             utm: {
//                 utmSource: getURLParameter('utm_source'),
//                 utmMedium: getURLParameter('utm_medium'),
//                 utmCampaign: getURLParameter('utm_campaign'),
//                 utmTerm: getURLParameter('utm_term'),
//                 utmContent: getURLParameter('utm_content'),
//             },
//         },
//     });
// };

const url = window.location.origin;
AOS.init();
const emitter = new EventEmitter();
// window.onload = function () {
//     document.body.classList.add('loaded_hiding');
//     window.setTimeout(function () {
//         document.body.classList.add('loaded');
//         document.body.classList.remove('loaded_hiding');
//     }, 500);
// };

// NOTE: Forms
{
    const $forms = document.querySelectorAll('[data-validation]');
    if ($forms.length) {
        for (let i = 0; i < $forms.length; i++) {
            new Validation($forms[i], {}).init();
        }
    }
}

// NOTE: Navbar
{
    const $navbar = document.querySelector('[data-nav]') || null;
    const $header = document.querySelector('[data-scroll]') || null;
    const scrollUp = 'scroll-up';
    const scrollDown = 'scroll-down';
    let lastScroll = 0;

    if ($navbar) {
        let navbar = null;
        const mq = window.matchMedia('(max-width: 1200px)');

        const checkMq = () => {
            if (mq.matches) {
                navbar?.destroy();
                navbar = new Navbar($navbar);
                navbar.init();
            } else if (navbar !== null) {
                navbar?.destroy();
                navbar = null;
            }
        };

        checkMq();
        mq.addListener(checkMq);
    }

    window.addEventListener('scroll', () => {
        const currentScroll = window.pageYOffset;
        if (currentScroll <= 0) {
            $header.classList.remove(scrollUp);
            $header.classList.remove(scrollDown);
            return;
        }
        if (currentScroll <= lastScroll) {
            $header.classList.add(scrollUp);
        }
        if (currentScroll > lastScroll && !$header.classList.contains(scrollDown)) {
            // down
            $header.classList.remove(scrollUp);
            $header.classList.add(scrollDown);
        } else if (currentScroll < lastScroll && $header.classList.contains(scrollDown)) {
            // up
            $header.classList.remove(scrollDown);
            $header.classList.add(scrollUp);
        }
        lastScroll = currentScroll;
        // let some = window.pageYOffset || document.documentElement.scrollTop;
    });
}
//Hidden section
function isInViewport(element) {
    const rect = element.getBoundingClientRect();
    return rect.top - window.innerHeight <= 0;
}
{
    const $elements = Array.from(document.getElementsByClassName(' hidden'));

    window.addEventListener('scroll', () => {
        $elements.map(el => {
            isInViewport(el) ? el.classList.remove('hidden') : el.classList.add('hidden');
        });
    });
}

//Animation

const animationDesign = document.getElementById('animationDesign');

if (animationDesign) {
    const designParams = {
        container: animationDesign,
        assetsPath: `${url}/wp-content/themes/dekryption/assets/images/animations/design/`,
        renderer: 'svg',
        autoplay: true,

        path: `${url}/wp-content/themes/dekryption/assets/images/animations/design/design.json`,
        // path: `${url}/wp-content/themes/dekryption/assets/images/5.json`,
    };
    let design = null;
    // isInViewport(animationDesign) ? (design = bodymovin.loadAnimation(designParams)) : (design = null);
    // window.addEventListener('scroll', () => {
    //     if (design === null) {
    //         isInViewport(animationDesign) ? (design = bodymovin.loadAnimation(designParams)) : (design = null);
    //         design && design.play();
    //     }
    // });
    design = bodymovin.loadAnimation(designParams);
    design.play();
}

const animationStrategic = document.getElementById('animationStrategic');
if (animationStrategic) {
    const strategicParams = {
        container: animationStrategic,
        assetsPath: `${url}/wp-content/themes/dekryption/assets/images/animations/strategic/`,
        renderer: 'svg',
        autoplay: true,
        path: `${url}/wp-content/themes/dekryption/assets/images/animations/strategic/strategic.json`,
    };
    let strategic = null;
    // isInViewport(animationStrategic) ? (strategic = bodymovin.loadAnimation(strategicParams)) : (strategic = null);
    // window.addEventListener('scroll', () => {
    //     if (strategic === null) {
    //         isInViewport(animationStrategic)
    //             ? (strategic = bodymovin.loadAnimation(strategicParams))
    //             : (strategic = null);
    //         strategic && strategic.play();
    //     }
    // });
    strategic = bodymovin.loadAnimation(strategicParams);
    strategic.addEventListener('complete', strategic.play());
}

const animationResearch = document.getElementById('animationResearch');
if (animationResearch) {
    const researchParams = {
        container: animationResearch,
        assetsPath: `${url}/wp-content/themes/dekryption/assets/images/animations/research/`,
        renderer: 'svg',
        autoplay: true,
        path: `${url}/wp-content/themes/dekryption/assets/images/animations/research/research.json`,
    };
    let research = null;
    // isInViewport(animationResearch) ? (research = bodymovin.loadAnimation(researchParams)) : (research = null);
    // window.addEventListener('scroll', () => {
    //     if (research === null) {
    //         isInViewport(animationResearch) ? (research = bodymovin.loadAnimation(researchParams)) : (research = null);
    //         research && research.play();
    //     }
    // });
    research = bodymovin.loadAnimation(researchParams);
    research.addEventListener('complete', research.play());
}

const animationWhatWeDo = document.getElementById('animationWhatWeDo');
if (animationWhatWeDo) {
    const whatWeDoParams = {
        container: animationWhatWeDo,
        assetsPath: `${url}/wp-content/themes/dekryption/assets/images/animations/whatWeDo/`,
        renderer: 'svg',
        autoplay: true,
        path: `${url}/wp-content/themes/dekryption/assets/images/animations/whatWeDo/What_we_do.json`,
    };
    let whatWeDo = null;
    whatWeDo = bodymovin.loadAnimation(whatWeDoParams);
    whatWeDo.play();
    // isInViewport(animationWhatWeDo) ? (whatWeDo = bodymovin.loadAnimation(whatWeDoParams)) : (whatWeDo = null);
    // window.addEventListener('scroll', () => {
    //     if (whatWeDo === null) {
    //         isInViewport(animationWhatWeDo) ? (whatWeDo = bodymovin.loadAnimation(whatWeDoParams)) : (whatWeDo = null);
    //         whatWeDo && whatWeDo.play();
    //     }
    // });
}

const animationVision = document.getElementById('animationVision');
if (animationVision) {
    const visionParams = {
        container: animationVision,
        assetsPath: `${url}/wp-content/themes/dekryption/assets/images/animations/vision/`,
        renderer: 'svg',
        autoplay: true,
        path: `${url}/wp-content/themes/dekryption/assets/images/animations/vision/Vision.json`,
    };
    let vision = null;
    // isInViewport(animationVision) ? (vision = bodymovin.loadAnimation(visionParams)) : (vision = null);
    // window.addEventListener('scroll', () => {
    //     if (vision === null) {
    //         isInViewport(animationVision) ? (vision = bodymovin.loadAnimation(visionParams)) : (vision = null);
    //         vision && vision.play();
    //     }
    // });
    vision = bodymovin.loadAnimation(visionParams);
    vision.play();
}

const animationApproach = document.getElementById('animationApproach');
if (animationApproach) {
    const approachParams = {
        container: animationApproach,
        assetsPath: `${url}/wp-content/themes/dekryption/assets/images/animations/approach/`,
        renderer: 'svg',
        autoplay: true,
        path: `${url}/wp-content/themes/dekryption/assets/images/animations/approach/Approach.json`,
    };
    let approach = null;
    // isInViewport(animationApproach) ? (approach = bodymovin.loadAnimation(approachParams)) : (approach = null);
    approach = bodymovin.loadAnimation(approachParams);
    approach.play();
    // window.addEventListener('scroll', () => {
    //     if (approach === null) {
    //         isInViewport(animationApproach) ? (approach = bodymovin.loadAnimation(approachParams)) : (approach = null);
    //         approach && approach.play();
    //     }
    // });
}
// NOTE: Slider media query
{
    const $sliders = document.querySelectorAll('[data-slider-mq]');

    if ($sliders.length) {
        $sliders.forEach($slider => {
            let swiper = null;
            const mq = window.matchMedia(`(max-width: ${$slider.dataset.sliderMq}px)`);

            const checkMq = () => {
                if (mq.matches) {
                    $slider.removeAttribute('class');
                    $slider.classList.add('swiper-container');
                    swiper = new Slider($slider);
                    swiper.init();
                } else if (swiper !== null) {
                    $slider.removeAttribute('class');
                    $slider.classList.remove('swiper-container');
                    swiper.destroy();
                    swiper = null;
                }
            };

            checkMq();
            mq.addListener(checkMq);
        });
    }
}

//Modal
const bioModal = document.getElementById('bioModal');
const bioBtn = document.querySelectorAll('.bioBtn');

const connectModal = document.getElementById('connectModal');
const connectBtn = document.querySelectorAll('.btn--connect');

const calendlyModal = document.getElementById('calendlyModal');
const calendlyBtn = document.querySelectorAll('.btn--header');

const $titleContainer = document.getElementById('title');
const $nameContainer = document.getElementById('name');
const $bioContainer = document.getElementById('bio');
const $photoContainer = document.getElementById('photo');

const onClickBio = async e => {
    const card = (e.currentTarget && e.currentTarget.getAttribute('key')) || null;

    await axios.get(`${url}/?get_user_info=${card}`).then(res => {
        $titleContainer.innerText = res.data.title;
        $nameContainer.innerText = res.data.name;
        $bioContainer.innerText = res.data.bio;
        $photoContainer.src = res.data.photo;
    });
    bioModal.style.display = 'block';
};

const onClickConnect = async e => {
    connectModal.style.display = 'block';
};

const onClickCalendly = async e => {
    const body = document.querySelector('body');
    const script = document.createElement('script');
    script.setAttribute('src', 'https://assets.calendly.com/assets/external/widget.js');
    body.appendChild(script);
    calendlyModal.style.display = 'block';
};

if (bioModal) {
    const bioModalContainer = new Modal(bioModal, bioBtn, onClickBio);
}

const connectModalContainer = new Modal(connectModal, connectBtn, onClickConnect);

const calendlyModalContainer = new Modal(calendlyModal, calendlyBtn, onClickCalendly);

let currentTab = 0;
showTab(currentTab);
const nextBtn = document.getElementById('nextBtn');
const steps = document.getElementsByClassName('step');

function fixStepIndicator(n) {
    const x = document.getElementsByClassName('step');
    for (let i = 0; i < x.length; i++) {
        x[i].className = x[i].className.replace(' active', '');
    }

    for (let i = 0; i < n; i++) {
        x[i].className += ' active';
    }
}

if (steps) {
    for (let i = 0; i < steps.length; i++) {
        steps[i].addEventListener('click', e => {
            setCurrentTab(i);
        });
    }
}
function nextPrev(n) {
    const x = document.getElementsByClassName('tab');

    x[currentTab].style.display = 'none';

    currentTab = currentTab + n;

    if (currentTab === x.length) {
        // document.getElementById('modal-form').submit();

        new Validation(document.getElementById('modal-form'), {}).init();
        connectModal.style.display = 'none';
        onClickCalendly();

        currentTab = 0;
        // nextBtn.setAttribute('type', 'submit');
    }
    if (currentTab === 1) {
        nextBtn.setAttribute('type', 'button');
    }
    showTab(currentTab);
}

function setCurrentTab(n) {
    const x = document.getElementsByClassName('tab');
    x[currentTab].style.display = 'none';
    currentTab = n;
    showTab(n);
}

function showTab(n) {
    const x = document.getElementsByClassName('tab');

    x[n].style.display = 'block';

    document.getElementById('questions-left').innerHTML = x.length - n - 1;
    if (x.length - n - 1 === 1) {
        document.getElementById('questions-end').innerHTML = '';
    } else {
        document.getElementById('questions-end').innerHTML = 's';
    }

    if (n == x.length - 1) {
        document.getElementById('nextBtn').innerHTML = 'Get the review';
    } else {
        document.getElementById('nextBtn').innerHTML = 'Next question';
    }
    fixStepIndicator(n + 1);
}

nextBtn.addEventListener('click', e => {
    nextPrev(1);
});

//calendly

// Data object with Team, Event Type, and UTM info
// var linkData = {
//     team: 'coffee-shop',
//     et: 'coffee-cupping',
//   }

//   // Tracking information object
//   var tracking = {
//     utmSource: 'Google',
//     utmMedium: 'cpc',
//     utmCampaign: 'fall_campaign',
//     utmTerm: 'coffee+shop',
//     utmContent: 'logolink',
//   }

//   // Object with Customer info
//   var customer = {
//     fname: 'Nick',
//     lname: 'Smith',
//     email: 'test@test.com',
//     a1: '',
//   }

//   // Generate Dynamic Path
//   var dynamicPath = 'https://calendly.com/' + linkData.team + '/' + linkData.et;

//   // Call the Calendly Badge Widget
//   Calendly.initBadgeWidget({
//       url: dynamicPath,
//       prefill: {
//         name: customer.fname + ' ' + customer.lname,
//         email: customer.email,
//         customAnswers: {
//           a1: customer.a1,
//         },
//         utm: {
//           utmCampaign: tracking.utmCampaign,
//           utmSource: tracking.utmSource,
//           utmMedium: tracking.utmMedium,
//           utmContent: tracking.utmContent,
//           utmTerm: tracking.utmTerm,
//         },
//       },
//       text: 'Hi ' + customer.fname + '! ' + 'Make a Reservation.',
//       color: '#000000',
//       textColor: '#ffffff',
//       branding: false
//   });

// Resize function
resizer({ emitter, ms: 300 });
