export default class Modal {
    constructor(modal, target, openFunc) {
        this.isOpen = false;
        this.modal = modal;
        this.target = target;
        this.closeModal = modal.querySelectorAll('[data-close]');
        this.target.forEach(btn => btn.addEventListener('click', openFunc));
        this.self = this.modal.closest('.modal-content');
        this.closeModal.forEach(item => {
            item.addEventListener('click', e => {
                this.close();
            });
        });

        this.modal.addEventListener('click', e => {
            if (!this.self) {
                if (e.target == this.modal) {
                    this.modal.style.display = 'none';
                }
            }
        });
    }

    close() {
        this.modal.style.display = 'none';
    }
}
